<template>
  <div>
    <b-card class="card mb-1" title="Data Request Pelatihan">
      <form @submit.prevent="submitForm()">
        <validation-observer ref="form">
          <div class="mt-2">
            <b-row v-if="!form.name">
              <b-col class="d-flex justify-content-center mt-4">
                <div class="text-center mt-4">
                  <b-spinner
                    variant="primary"
                    class="mr-1"
                  />
                  <p class="mt-1">
                    Memuat Data Request Pelatihan
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row v-else>

              <b-col sm="12" class="mb-1"
                ><b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Pelatihan"
                    rules="required|max:200"
                  >
                    <label class="d-block">Nama Pelatihan</label>
                    <b-form-input
                      v-model="form.name"
                      placeholder="Masukkan Nama Pelatihan"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="12" class="mb-1"
                ><b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Tujuan Pelatihan"
                  >
                    <label class="d-block">Tujuan Pelatihan</label>
                    <b-form-input
                      v-model="form.purpose"
                      placeholder="Masukkan Tujuan Pelatihan"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col sm="12" class="mb-1"
                ><b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Deskripsi Pelatihan"
                  >
                    <label class="d-block">Deskripsi Pelatihan</label>
                    <quill-editor v-model="form.description" />
                    <b-form-invalid-feedback id="input-live-feedback">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
            <div>
              <b-button variant="primary" class="float-right" type="submit">
                <div class="d-flex align-items-center">
                  <b-spinner
                    v-show="isLoading"
                    class="mr-1"
                    variant="light"
                    small
                    @click.prevent="submitForm"
                  />
                  Simpan
                </div>
              </b-button>
            </div>
          </div>
        </validation-observer>
      </form>
    </b-card>
  </div>
</template>

<script>
// Components
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/id.json";
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormSelect,
  BFormInvalidFeedback,
  BSpinner,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

import {
  reqGetTrainingRequest,
  reqUpdateTrainingRequest,
} from "@/api/user/trainingRequest";

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  });
});

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,

    vSelect,
    flatPickr,

    ValidationProvider,
    ValidationObserver,

    quillEditor
  },
  data() {
    return {
      isLoading: false,
      form: {
        name: "",
        purpose: "",
        description: ""
      },
    };
  },
  mounted(){
    this.getData();
  },
  methods: {
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      });
    },
    getData() {
      this.isLoading = true;
      reqGetTrainingRequest(this.$route.params.trainingRequestId)
        .then((response) => {
          this.form.name = response.data.data.name;
          this.form.purpose = response.data.data.purpose;
          this.form.description = response.data.data.description;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Gagal",
            error.response.data.message || "Data tidak ditemukan"
          );
        });
    },
    submitForm() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.isLoading = true;
          reqUpdateTrainingRequest(this.$route.params.trainingRequestId, this.form)
            .then((response) => {
              // stop loading
              this.isLoading = false;

              // show success toast
              this.showToast(
                "success",
                "Check",
                "Berhasil",
                response.data.message || "Request Pelatihan Berhasil Disimpan"
              );

              this.$router.push({
                name: "user-training-requests.show",
                params: {
                  trainingRequestId: response.data.data.id
                }
              });
            })
            .catch((error) => {
              this.isLoading = false;
              this.$refs.form.setErrors(this.mapErrorsField(error.response.data.errors));

              // show error toast
              this.showToast(
                "danger",
                "AlertTriangleIcon",
                "Gagal",
                error.response.data.message || "Request Pelatihan Gagal Disimpan"
              );
            });
        }
      })
    },
    mapErrorsField(errors) {
      const mapErrors = []
      const fields = {
        'name': 'Nama Pelatihan',
        'purpose': 'Tujuan Pelatihan',
        'description': 'Deskripsi Pelatihan'
      }
      for (let field in errors) {
        mapErrors[fields[field] ? fields[field] : field] = errors[field]
      }
      return mapErrors
    }
  },
};
</script>
